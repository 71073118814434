import React from "react";
import {
  Layout,
  CaseStudyHeader,
  // CaseStudyNav,
  ContactForm,
} from "../components";
import locales from "../constants";

const pageCaseStudy = ({ pageContext }) => {
  const {
    title,
    content,
    image,
    logo,
    subheader,
    description,
    list,
    mainColor,
    logoHeight,
    seo,
  } = pageContext.data;
  const listNumb = list.length;

  return (
    <Layout
      seo={{
        title: title,
        href: "pl",
        lang: "pl",
        customCanonical: seo.yoast_wpseo_canonical,
      }}
      header={{
        backgroundColor: `#${mainColor}`,
        icons: `#${mainColor}`,
        navClass: "",
        ogImage: image,
      }}
    >
      <CaseStudyHeader
        title={title}
        background={image}
        logo={logo}
        height={logoHeight}
      />
      <section
        className={`container-fluid case_study_single_section_top ${
          listNumb > 1 ? "" : "list_empty"
        }`}
        id="info"
      >
        {title ? (
          <h1 style={{ color: `#${mainColor}` }}>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </h1>
        ) : (
          ""
        )}
        {subheader ? <h4>{subheader}</h4> : ""}
        {listNumb > 1 ? (
          <div className="row custom_row">
            <div className="col-md-6 col_1">
              {list.map((innerField, index) => (
                <div className="row" key={index}>
                  <div className="col-md-4">
                    <strong>{innerField.case_study_left_list_header}:</strong>
                  </div>
                  <div className="col-md-7">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: innerField.case_study_left_list_content,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-6">
              {description ? <p>{description}</p> : ""}
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
      <section className="case_study_single_section_main">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </section>
      <section className="case_study_single_section_contact">
        <div className="inner">
          <h2>Kontakt</h2>
          <ContactForm locales={locales["pl"]} lang="pl" />
        </div>
      </section>

      {
        // <CaseStudyNav />
      }
    </Layout>
  );
};

export default pageCaseStudy;
